export interface ClientResponse<T> {
  error?: string;
  data?: T;
}

export interface CoachCoachees {
  id: number;
  coach_id: number;
  coachee_id: number;
  group_name: string;
}

export enum MessageStatus {
  QUEUED = 'queued',
  SENT = 'sent', 
  DELIVERED = 'delivered',
  FAILED = 'failed'
}


export interface Coachee {
  id: number;
  coach_id: number;
  first_name: string;
  last_name: string;
  phone: string;
  email: string;
  group_name: string;
  eventCount: number;
  latestAttendedAtDate: string;
  latestStatusUpdate: any;
  CoachCoachees: CoachCoachees[];
}

export interface Coach {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  calendly_api_token: string;
}

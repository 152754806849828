import { ClientResponse } from '../interfaces';


const SERVER_URL = window.location.hostname === 'localhost' ? 'http://localhost:8080' : window.location.origin;
const token = localStorage.getItem('token');

// TODO: Remember how to organize how to return json.data and coordinate with server

export function GET(path: string): Promise<ClientResponse<any>> {
  const defaultOptions: RequestInit & { headers: { 'Content-Type': string; 'Authorization'?: string } } = {
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
    },
  };

  if (token) {
    defaultOptions.headers['Authorization'] = `Bearer ${token}`;
  }

  return fetch(SERVER_URL + path, defaultOptions)
    .then((res) => {
      return res.json();
    })
    .then((json) => {
      return { data: json.data };
    })
    .catch((e: any) => {
      return handleHttpError(e, path);
    });
}

export function POST(path: string, body: any): Promise<ClientResponse<any>> {
  const jsonBody = JSON.stringify(body);
  const defaultOptions: RequestInit & { headers: { 'Content-Type': string; 'Authorization'?: string } } = {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    body: jsonBody,
  };

  if (token) {
    defaultOptions.headers['Authorization'] = `Bearer ${token}`;
  }

  return fetch(SERVER_URL + path, defaultOptions)
    .then((res) => {
      return res.json();
    })
    .then((json) => {
      if (json.error) {
        return { error: json.error };
      }

      if (json.data) {
        return { data: json.data };
      }

      return { error: 'No data returned' };
    })
    .catch((e: any) => {
      return handleHttpError(e, path);
    });
}

export function PUT(path: string, body: any): Promise<ClientResponse<any>> {
  const jsonBody = JSON.stringify(body);
  const defaultOptions: RequestInit & { headers: { 'Content-Type': string; 'Authorization'?: string } } = {
    method: 'put',
    headers: {
      'Content-Type': 'application/json',
    },
    body: jsonBody,
  };

  if (token) {
    defaultOptions.headers['Authorization'] = `Bearer ${token}`;
  }

  return fetch(SERVER_URL + path, defaultOptions)
    .then((res) => {
      return res.json();
    })
    .then((json) => {
      return { data: json.data };
    })
    .catch((e: any) => {
      return handleHttpError(e, path);
    });
}

const handleHttpError = (e: any, path: string) => {
  // console.log(e)
  return {
    error: 'Something failed',
  };
};

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CssVarsProvider } from '@mui/joy/styles';
import { POST } from '../api/requestClient';
import CssBaseline from '@mui/joy/CssBaseline';
import Box from '@mui/joy/Box';
import Typography from '@mui/joy/Typography';
import FormHelperText from '@mui/joy/FormHelperText';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Input from '@mui/joy/Input';
import Button from '@mui/joy/Button';
import { SignUpUser } from '../api/serverClient';

export default function SignUp() {
  const navigate = useNavigate();

  const [coach, setCoach] = useState({
    first_name: '',
    last_name: '',
    phone: '',
    email: '',
    password: '',
    confirmPassword: '',
  });
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [isPasswordValid, setIsPasswordValid] = useState(true);
  const [isFormValid, setIsFormValid] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [passwordErrors, setPasswordErrors] = useState<string[]>([]);

  const validateEmail = (value: string) => {
    //Must contain @ and something after .
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setIsEmailValid(emailPattern.test(value));
  };

  const validatePassword = (password: string) => { 
    const errors: string[] = [];
    // Check minimum length and uppercase
    if (!/^(?=.*[A-Za-z]).{8,}$/.test(password)) {
      errors.push('Be at least 8 characters long and contain an uppercase letter');
    }
  
    // Check special characters
    if (!/[!@#$%^&*()_+\-=\[\]{};:'",.<>/?]/.test(password)) {
      errors.push('Include at least one special character');
    }
  
    // Check numbers
    if (!/\d/.test(password)) {
      errors.push('Include at least one number');
    }

    setPasswordErrors(errors)
    setIsPasswordValid(errors.length === 0);
  }

  const handleChange = (e: any) => {
    setCoach({
      ...coach,
      [e.target.name]: e.target.value,
    });
    validateForm();
  };

  const validateForm = () => {
    if (
      coach.first_name !== '' &&
      coach.first_name.length > 0 &&
      coach.last_name !== '' &&
      coach.last_name.length > 0 &&
      coach.phone.length > 9
    ) {
      setIsFormValid(true);
    } else {
      setIsFormValid(false);
    }
  };

  async function handleSignUp() {
    if (coach.password !== coach.confirmPassword) {
      alert('Passwords do not match');
      return;
    }
    if (!isFormValid) {
      alert('Form is not valid, fill out all fields');
      return;
    }

    try {
      const response = await SignUpUser(coach);
      if (response.error) {
        alert(response.error);
      } else {
        alert('Successfully Registered');
        navigate('/login')
      }
    } catch (err) {
      console.log('error', err);
    }
  }

  return (
    <CssVarsProvider disableTransitionOnChange>
      <CssBaseline />
      <Box
        component='main'
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: '100vh',
          gap: 2,
          p: 2,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      >
        <Box sx={{ width: '100%', maxWidth: '400px', border: '2px solid #D3D3D3', borderRadius: '8px', px: 2, py: 3, boxShadow: 'md' }}>
          <Typography level='h3' component='h1' sx={{ textAlign: 'center', marginBottom: ' 20px' }}>
            Sign Up
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <FormControl id='firstName' required size='sm' color='primary'>
              <FormLabel sx={{ marginBottom: 1, fontSize: '20px' }}>First Name</FormLabel>
              <Input
                type='text'
                placeholder='First Name'
                value={coach.first_name}
                name='first_name'
                onChange={handleChange}
              />
            </FormControl>

            <FormControl id='lastName' required size='sm' color='primary'>
              <FormLabel sx={{ marginBottom: 1, fontSize: '20px' }}>Last Name</FormLabel>
              <Input
                type='text'
                placeholder='Last Name'
                value={coach.last_name}
                name='last_name'
                onChange={handleChange}
              />
            </FormControl>

            <FormControl id='phone' required size='sm' color='primary'>
              <FormLabel sx={{ marginBottom: 1, fontSize: '20px' }}>Phone</FormLabel>
              <Input
                type='text'
                placeholder='Phone'
                value={coach.phone}
                name='phone'
                onChange={handleChange}
              />
            </FormControl>

            <FormControl error={!isEmailValid} id='email' required size='sm' color='primary'>
              <FormLabel sx={{ marginBottom: 1, fontSize: '20px' }}>Email</FormLabel>
              <Input
                type='email'
                placeholder='Email'
                value={coach.email}
                name='email'
                onChange={(e) => {
                  handleChange(e);
                  validateEmail(e.target.value);
                }}
              />
            </FormControl>

            <FormControl error={!isPasswordValid} id='password' required size='sm' color='primary'>
              <FormLabel sx={{ marginBottom: 1, fontSize: '20px' }}>Password</FormLabel>
              <Input
                type={showPassword ? 'text' : 'password'}
                placeholder='Create Password'
                value={coach.password}
                name='password'
                onChange={(e) => {
                  handleChange(e);
                  validatePassword(e.target.value);
                }}
              />

              {!isPasswordValid && (
                <FormHelperText>
                  <div className="password-requirements text-sm text-gray-600" style={{ color: 'red', fontSize: '1.0em' }}>
                    <p>Password must:</p>
                    <ul className="list-disc pl-5 mt-1">
                      {passwordErrors.map((error, index) => (
                        <li key={index}>{error}</li>
                      ))}
                    </ul>
                  </div>
                </FormHelperText>
              )}
            </FormControl>

            <FormControl id='confirmPassword' required size='sm' color='primary'>
              <FormLabel sx={{ marginBottom: 1, fontSize: '20px' }}>Confirm Password</FormLabel>
              <Input
                type='password'
                placeholder='Confirm Password'
                value={coach.confirmPassword}
                name='confirmPassword'
                onChange={handleChange}
              />
            </FormControl>
          </Box>
          <Box
            sx={{ display: 'flex', justifyContent: 'center', paddingTop: '20px', gap: 2, mt: 2 }}
          >
            <Button
              type='submit'
              sx={{ bgcolor: '#007BFF', width: '100%' }}
              onClick={(e) => handleSignUp()}
              fullWidth
              disabled={!isEmailValid || !isPasswordValid || !isFormValid}
            >
              Sign Up
            </Button>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center',  paddingTop: '20px' }}>
                Have an account?&nbsp;
                <Typography
                component='a'
                sx={{ color: '#007BFF', textDecoration: 'none', cursor: 'pointer'}}
                onClick={() => navigate('/login')}
                >
                Log In
                </Typography>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center', paddingTop: '20px', gap: 2, mt: 2 }}></Box>
            <Button 
              type='submit' 
              sx={{ bgcolor: '#4CAF50' }} 
              fullWidth 
              onClick={() => navigate('/login')}
            >
              Go to Login
            </Button>
        </Box>
      </Box>
    </CssVarsProvider>
  );
}
